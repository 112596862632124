@import "../../../../Assets/sass/_variables"
.input_active
  input
    padding: 27px 25px 10px 30px !important

.inputField
  flex-grow: 1
  overflow: hidden
  position: relative
  min-height: 94px

  .pass_reveal
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    position: absolute
    right: 32px
    top: 20px
    stroke: #181818
    cursor: pointer
    &:hover
      stroke: $skyblue

    

  .placeholder
    pointer-events: none
    position: absolute
    left: 32px
    top: 20px
    font-size: $sm
    font-family: MontserratBold
    span
      margin-left: 5px
      color: #EE5253

  .helperText
    margin-top: 5px
    font-size: $xxxs
    color: #181818
    font-family: 'MontserratRegular'

  input,textarea
    // margin-bottom: 30px
    resize: none
    padding: 18px 25px 19px 30px
    border-radius: 10px
    border: solid 1px #f5f5f5
    background-color: #f5f7f8
    font-size: $sm
    outline: none
    width: 100%
    box-sizing: border-box
    font-family: MontserratBold
    @media (min-width: $Small)
      &:focus
        outline: none !important
        border: solid 1px #3e66f8
  .capitalize
    text-transform: capitalize
  .text_bold
    font-family: MontserratBold

  .required
    color: #EE5253
  .errorField
    border: 1px solid #EE5253
    &:focus
      border-color: #EE5253

  label
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    pointer-events: none
    position: absolute
    left: 32px
    top: 0
    font-size: $xxs
    color: #181818
    font-family: 'MontserratRegular'
    cursor: text
    gap: 0
    display: flex
    align-items: center
    padding: 11px 0
    @media (min-width: $Small)
      font-size: $sm
  .required_small
    font-size: $xxxs

  input[type="date"]
    background: #f5f7f8 url(../../../../Assets/Icons/calendar-solid.svg) right no-repeat
    background-size: 20px 20px
    background-position: right 33px center

  input[type="date"]::-webkit-calendar-picker-indicator
    background: transparent
    color: transparent
    cursor: pointer
    position: absolute
    width: 100%
    height: 60px
    @media (min-width: $Small)
      width: 450px

@import "../../../../Assets/sass/_variables"

.tabs
    display: flex
    align-items: center
    width: 100%

.activeTab
    cursor: pointer
    flex: 1
    text-align: center
    color: $skyblue
    font-family: 'MontserratRegular'
    transition: all 300ms
    .divider
        width: 100%
        background-color: $skyblue
        border-radius: 4px
        height: 2px
        transition: all 300ms
        margin-top: 10px

.tab
    cursor: pointer
    flex: 1
    text-align: center
    color: $darkGrey
    font-family: 'MontserratRegular'
    transition: all 300ms
    .divider
        width: 100%
        background-color: $darkGrey
        border-radius: 4px
        height: 2px
        transition: all 300ms
        margin-top: 10px
    &:hover
        color: $skyblue
        .divider
            background-color: $skyblue

.comparePage
    .tab
        color: $arrowColor
        .divider
            background: transparent

    .tab, .activeTab
        @media screen and (min-width: $Small)
            flex: unset
            margin-right: 40px
        >div
            display: flex
            align-items: center
            justify-content: center
            &:first-child
                >div
                    height: 40px
                    display: flex
                    flex-direction: column
                    justify-content: end
                    text-align: start
                    font-size: $newXS
                    font-family: 'MontserratRegular'

                    @media screen and (min-width: $Small)
                        font-size: $xs
                    @media screen and (min-width: $Extra-large)
                        font-size: $sm

                    span
                        color: $yellow
                        font-size: $xxxs
        .divider
            margin-top: 20px

    .activeTab
        z-index: 10

        >div
            font-weight: 600

@import "../../../../Assets/sass/_variables"
.chekbox
  cursor: pointer
  width: 30px
  height: 30px
  background-color: #EFF3F5
  border-radius: 4px
  flex-shrink: 0

.white
  background: $white
  border: 1px solid $arrowColor

.checked
  background-color: $skyblue
  cursor: pointer
  width: 30px
  height: 30px
  border-radius: 4px
  display: flex
  align-items: center
  flex-shrink: 0
  justify-content: center

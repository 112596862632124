@import "../../../../Assets/sass/_variables"
@import "../../../../Assets/sass/mixins"

.infoForm
    padding: 30px

.submitButton
    width: 100%
    background-color: $skyblue
    transition: all 300ms
    &:hover
        background-color: $yellow

.textarea
    margin-top: 20px
    textarea
        height: 67vh
        font-family: 'MontserratRegular'
        font-size: $xs

.leftCharacters    
    font-family: 'MontserratRegular'
    margin-bottom: 30px
    font-size: $xs
    color: $lightgrey
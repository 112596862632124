@import "../../../Assets/sass/_variables"
@import "../../../Assets/sass/_mixins"

.icon
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer

.partner_logo_gegidze
  cursor: pointer
  height: 115px
  width: 250px
  background-repeat: no-repeat
  background-size: contain
  background-position: right
  background-image: url('https://softwaregecdn.azureedge.net/websitefiles/images/partners/gegidze-standard.png')

  &:hover
    background-image: url('https://softwaregecdn.azureedge.net/websitefiles/images/partners/gegidze.png')

.partner_logo
  cursor: pointer

.partner_logo_hover
  display: none
  &:hover

.partner_logo_container
  height: 100%
  display: flex
  align-items: center
  position: relative
  &:hover &__partner_logo
    opacity: 0

  &:hover &__partner_logo_hover
    opacity: 1

  &__partner_logo_hover
    position: absolute
    opacity: 0
    transition: all 300ms

  &__partner_logo
    opacity: 1
    transition: all 300ms

.slider
  width: 90vw
  display: none
  @media screen and (min-width: $Small)
    display: flex
    flex-direction: column
    align-items: center

.scrollable_carousel
  width: 100%
  margin-top: 50px
  margin-bottom: 110px
  display: flex
  align-content: center
  justify-content: center
  gap: 0px
  :global(.swiper-slide)
    flex-shrink: 1 !important
    width: auto !important

.carousel_images
  flex: 1

.numbers_containter
  width: 100%
  background-color: #F5F7F8
  padding-top: 110px
  padding-bottom: 110px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  h3
    @include title_md

.numbers_stats
  display: grid
  gap: 40px
  grid-template-columns: 1fr
  @media screen and (min-width: $Large)
    grid-template-columns: auto auto

.numbers_stat_item
  display: flex
  align-items: center
  gap: 25px
  h4
    font-size: 28px
    font-family: 'MontserratSemiBold'
    color: #181818
    @media screen and (min-width: $XX-Small)
      font-size: 30px
    @media screen and (min-width: $Extra-large)
      font-size: 40px
  p
    max-width: 160px
    font-size: 18px
    font-family: 'MontserratMedium'
    color: #181818
    @media screen and (min-width: $Small)
      max-width: 300px
    @media screen and (min-width: $Extra-large)
      font-size: 24px

.numbers_stat_icon
  width: 72px
  height: 72px
  display: flex
  align-items: center
  justify-content: center
  background-color: $purple
  border-radius: 10px

.divider
  background-color: $white
  height: 30px
  width: 1px
  display: none
  @media screen and (min-width: $Small)
    display: block

.top_social_hotline_wr
  display: flex
  flex-direction: column
  align-items: center
  gap: 25px
  margin-top: 110px
  @media screen and (min-width: $Small)
    flex-direction: row

.top_social_phone
  font-family: 'MontserratBold'
  font-size: 16px
  color: $white
  @media screen and (min-width: $Extra-extra-large)
    font-size: 21px
  &:visited
    color: $white

.numbers_link
  color: $skyblue
  &:visited
    color: $skyblue

.numbers_content
  display: flex
  flex-direction: column
  align-items: center
  gap: 130px
  @media screen and (min-width: $Small)
    flex-direction: row
    gap: 45px
  @media screen and (min-width: $Medium)
    gap: 22px
  @media screen and (min-width: $Large)
    flex-direction: row
    gap: 45px
  @media screen and (min-width: $Extra-large)
    flex-direction: row
    gap: 75px
  @media screen and (min-width: $Extra-extra-large)
    flex-direction: row
    gap: 123px

.vacancies_img
  max-width: 100%
  @media screen and (min-width: $Small)
    display: none
  @media screen and (min-width: $Medium)
    display: block

.vacancies_img_sm
  display: none
  @media screen and (min-width: $Small)
    display: block
  @media screen and (min-width: $Medium)
    display: none

.card
  box-shadow: 0px 0px 30px #1818181A
  border-radius: 10px
  padding: 15px
  display: flex
  gap: 10px
  @media screen and (min-width: $Small)
    padding: 20px
    gap: 20px
  @media screen and (min-width: $Medium)
    padding: 30px
    gap: 27px
  .header
    @include title_sm
  .p
    @include font
    text-align: left
    margin-top: 21px
    font-size: 13px
    line-height: 24px
  .svg
    width: 30px
    height: 30px

.footer_containter
  margin-bottom: -30px
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  padding-top: 195px
  padding-bottom: 33px

.title_foot
  text-align: center
  margin-top: 30px
  @include title_md

.footer_bg
  z-index: -1
  position: absolute
  bottom: 0
  width: 100%
  height: 350px
  background-image: url('https://softwaregecdn.azureedge.net/websitefiles/images/partners/partners-footer.png')
  background-repeat: no-repeat
  background-size: cover
  background-position: center

.title
  font-family: 'MontserratRegular'
  font-size: 55px
  line-height: 67px
  text-align: center
  @media screen and (max-width: $Large)
    font-size: 34px
    line-height: 42px
  @media screen and (max-width: $X-Small)
    // margin: 0 0 21px 0px
    font-size: 32px
    line-height: 39px

.signup_button
  margin-top: 55px
  background-color: #F7C63E
  color: $white
  font-family: 'MontserratBold'
  font-size: $xs
  padding: 11px 29px 10px 29px
  border-radius: 10px
  width: 75%
  @media screen and (min-width: $Small)
    width: auto
  @include buttonHover($color: $skyblue)
  @media screen and (min-width: $Extra-large)
    font-size: $sm
    padding: 19px 33px

@import "../../../../Assets/sass/_variables"

.sectionHeader
    display: flex
    align-items: center
    gap: 30px
    margin: 0 auto

    .divider
        width: 2px
        height: 30px
        background-color: $codGray
    .heading
        font-size: $sm
        font-family: 'MontserratRegular'
        color: $dark
        @media screen and (min-width: $Small)
            font-size: $smd
        @media screen and (min-width: $Extra-large)
            font-size: $lg

@import "../../../../Assets/sass/_variables"

.tooltipModal
    display: flex
    align-items: flex-end
    position: fixed
    top: 0
    left: 0
    height: 100%
    width: 100%
    background-color: #0000006f
    z-index: 1000

    @media screen and (min-width: $Small)
        display: none

    .tooltipModalWrapper
        width: 100%
        background: $white
        padding: 20px
        border-top-left-radius: 10px
        border-top-right-radius: 10px
        display: grid
        gap: 12px

        h5
            font-family: "MontserratBold"
            font-size: $xs

        span
            display: flex
            align-items: center

            svg
                margin: 0 12px 0 0
                height: 18px
                width: 18px
                path
                    fill: $skyblue!important

            p
                font-size: $xxs
                font-family: 'MontserratRegular'

            a
                margin-left: 5px
                font-size: $xxs
                font-family: 'MontserratRegular'

        .notVerified
            svg
                path
                    fill: $yellow!important

        button
            border: 1px solid $lightgrey
            line-height: 19px
            font-size: $xxs
            border-radius: 20px
            font-family: 'MontserratBold'
            padding: 5px 10px

@import "../../../../Assets/sass/_variables"

.countryCodeDropdown
    min-height: 60px
    background: $grayblue
    border-radius: 10px
    padding: 10px
    display: flex
    align-items: center
    position: relative
    min-width: 110px
    justify-content: center
    .wrapper

        .chosenVal
            cursor: pointer
            background: $grayblue
            display: flex
            align-items: center

            p
                font-family: 'MontserratBold'
                font-size: $sm

            svg
                margin-left: 5px
                transition: all 0.3s linear

        .options
            position: absolute
            left: 0
            top: 62px
            display: grid
            gap: 10px
            max-height: 0
            width: 110px
            overflow-x: hidden
            overflow-y: auto
            background: $grayblue
            z-index: 100
            border-radius: 10px
            transition: max-height 0.3s linear, padding 0.3s linear

            span
                padding: 0 20px
                white-space: nowrap
                cursor: pointer

            .searchWrapper
                padding: 5px
                position: sticky
                top: 0
                background: $grayblue

                input
                    width: 100%
                    height: 30px
                    padding: 0 5px
                    background-color: $white
                    border-radius: 4px
                    font-family: 'MontserratBold'

        .open
            max-height: 300px
            border: solid 1px $boldwhite
            padding: 0 0 10px

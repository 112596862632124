@import "../../../../Assets/sass/_variables"

.language

  .languageWrapper
    position: relative
    .chosenLanguage
      cursor: pointer
      padding: 11px 10px 10px
      border: 1px solid transparent
      border-radius: 10px
      font-size: $sm
      font-family: 'MontserratBold'
      display: flex
      align-items: center

      @media screen and (min-width: $Medium)
        font-size: $xs
      @media screen and (min-width: $Large)
        font-size: $sm
      @media screen and (min-width: $Extra-large )
        padding: 12px 10px

        svg
          margin-left: 5px
          transition: all 0.3s linear

      span
        display: flex
        align-items: center

    .options
      position: absolute
      left: 0
      bottom: -40px
      width: 100%
      border-bottom: 1px solid $skyblue
      border-left: 1px solid $skyblue
      border-right: 1px solid $skyblue
      border-bottom-left-radius: 10px
      border-bottom-right-radius: 10px
      background: $white

      >button
        padding: 12px 10px
        cursor: pointer
        font-family: 'MontserratBold'
        display: flex
        align-items: center
        font-size: $sm
        margin: 0 auto

        @media screen and (min-width: $Medium)
          font-size: $xs
        @media screen and (min-width: $Large)
          font-size: $sm

        &:first-child
          border-top: 1px solid $boldwhite

  .open
    .chosenLanguage
      border-radius: unset
      border-top: 1px solid $skyblue
      border-right: 1px solid $skyblue
      border-left: 1px solid $skyblue
      border-top-left-radius: 10px
      border-top-right-radius: 10px

      svg
        transform: rotate(180deg)

.footer
  .languageWrapper
    .chosenLanguage
      border-top: 0
      border-top-left-radius: 0
      border-top-right-radius: 0
      border-radius: 10px
      background: $white
      padding: 6px 10px 5px

    .options
      top: -27px
      bottom: unset
      border-bottom: 0
      border-bottom-left-radius: 0
      border-bottom-right-radius: 0
      border-top: 1px solid $skyblue
      border-top-left-radius: 10px
      border-top-right-radius: 10px
      display: flex
      align-items: center
      justify-content: center
      button
        border-bottom: 1px solid $boldwhite
        padding: 3px 10px
        margin: 0 0 2px

.footer
  .open
    .chosenLanguage
      border-radius: unset
      border-bottom: 1px solid $skyblue
      border-bottom-left-radius: 10px
      border-bottom-right-radius: 10px

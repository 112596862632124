@import "../../../../Assets/sass/variables"

.button
  background-color: $yellow
  color: $white
  font-size: $sm
  height: 44px
  width: 140px
  border: none
  border-radius: 12px
  @media screen and (max-width: $Small)
    height: 40px
    width: 124px
    font-size: $xs

@import "./Assets/sass/_variables"
.react-dropdown-select-dropdown
    border-radius: 10px !important
    box-shadow: 0px 0px 30px $grey !important

.react-dropdown-select-dropdown-handle
    svg
        width: 25px !important
        height: 25px !important
        color: #747474

.type-success
    background: #eafff2 !important
    span
        color: $lightGreen

.type-success.place-top::after
    border-top-color: #eafff2 !important

.type-warning
    background: #fcc2c236 !important
    span
        color: #ee5253

.type-waning.place-top::after
    border-top-color: #fcc2c236 !important

.contentEditor
    .DraftEditor-editorContainer
        ul

            li
                list-style-type: disc
                &::marker
                    text-align: center
                    font-size: 25px
                    color: $skyblue

@layer base
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button
        -webkit-appearance: none
        margin: 0

::-webkit-scrollbar
    width: 8px

::-webkit-scrollbar-track
    background: #e2e2e2

::-webkit-scrollbar-thumb
    background: #c2c2c2
    border-radius: 10px

@import '../../../../Assets/sass/_variables'

.option
    display: flex
    align-items: center
    margin: 0 0 20px
    .radio
        position: relative
        width: 30px
        height: 30px
        border-radius: 50%
        display: inline-flex
        align-items: center
        justify-content: center
        cursor: pointer
        flex-shrink: 0
        .check
            height: 12px
    .input
        width: 30px
        height: 30px
        border: none
        opacity: 0
        z-index: 10
        position: fixed
        cursor: pointer
    .label
        font-size: $sm
        font-family: "MontserratBold"
        color: $dark
        margin-left: 16px
        cursor: pointer

@import "../../../Assets/sass/variables"

$bgColor: #69C2A3
$dotSize: 1.5rem
$duration: 2.5s

.loaderWrapper
    background: rgba(0,0,0,0.3)
    display: grid
    place-content: center
    position: fixed
    height: 100%
    top: 0
    left: 0
    width: 100%
    z-index: 100
    .loader
        animation: rotate $duration linear infinite normal
        transform-origin: 50% 50%
        position: relative
        .loader__ball
            height: $dotSize
            left: -$dotSize / 2
            position: absolute
            top: -$dotSize / 2
            transform-origin: 50% 50%
            width: $dotSize
            &:nth-of-type(2)
                transform: rotate(120deg)

            &:nth-of-type(3)
                transform: rotate(240deg)

            &::after
                animation: move $duration / 2 ease-in-out infinite alternate
                background-color: $skyblue
                border-radius: 50%
                content: ""
                display: inline-block
                height: 100%
                width: 100%
                transform-origin: 50% 50%

@keyframes rotate
    from
        transform: rotate(0)

    to
        transform: rotate(360deg)

@keyframes move
    0%, 15%
        transform: translateY(0)

    100%
        transform: translateY(-150%)

@import "./_variables"
@mixin font($fontWeight: regular)
    font-family: 'MontserratRegular'
    font-weight: normal
    @if $fontWeight == 'bold'
        font-family: 'MontserratBold'
    @if $fontWeight == 'semiBold'
        font-family: 'MontserratSemiBold'

@mixin tag
    display: inline-block
    margin-right: 10px
    margin-bottom: 10px
    color: $dark
    // font: normal normal 600 14px/30px Montserrat
    white-space: nowrap
    font-family: 'MontserratSemiBold'
    border: 1px solid $versusGray
    padding: 9px 24px
    border-radius: 26px
    font-size: 14px
    flex-grow: 0

    @media screen and (min-width: $Large)
        font-size: $xs

    div
        display: inline-block

    svg
        height: 8px
        width: 8px
        margin-left: 7px
        cursor: pointer
        path
            stroke: #747474
            stroke-width: 5px

@mixin More
    display: inline-block
    margin-right: 10px
    margin-bottom: 10px
    color: $dark
    // font: normal normal 600 14px/30px Montserrat
    white-space: nowrap
    padding: 12px 20px
    box-shadow: 0px 0px 30px $grey
    border-radius: 26px
    font-size: 14px
    flex-grow: 0
    font-family: 'MontserratRegular'

    @media screen and (min-width: $Large)
        font-size: $xs

    span
        display: inline-block
        font-family: 'MontserratSemiBold'

@mixin tagCategory
    color: #3E66F8
    font-family: 'MontserratBold'
    font-weight: bold
    font-size: 14px
    margin: 10px 0

@mixin button ($backgroundColor, $borderColor, $textColor)
    font-family: 'MontserratBold'
    font-weight: bold
    height: inherit
    margin-top: 35px
    padding-top: 11px
    padding-bottom: 11px
    width: 100%
    background-color: $backgroundColor
    border: 2px solid $borderColor
    color: $textColor

@mixin buttonHover ($color)
    transition: background-color 300ms
    &:hover
        border-color: $color
        background-color: $color

@mixin title
    font-size: 34px
    font-family: 'MontserratRegular'
    color: #181818
    font-weight: normal

@mixin subTitle
    font-size: 16px
    font-family: 'MontserratRegular'
    color: #181818
    font-weight: normal
    margin-top: 13px

@mixin title_md
    font-size: $lg
    line-height: 42px
    font-family: 'MontserratRegular'
    color: #181818
    font-weight: normal
    @media screen and (max-width: $Large)
        font-size: $smd
        line-height: 25px

@mixin title_sm
    font-size: 21px
    line-height: 25px
    font-family: 'MontserratSemiBold'
    color: #181818
    @media screen and (max-width: $Extra-large)
        font-size: 18px
        line-height: 22px

@mixin subTitle_md
    font-size: 16px
    line-height: 19px
    font-family: 'MontserratRegular'
    color: #181818
    font-weight: normal
    margin-top: 13px
    @media screen and (max-width: $Extra-large)
        font-size: 14px
        line-height: 18px

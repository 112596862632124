@import "../../Assets/sass/_variables"
@import "../../Assets/sass/_mixins"

.partners
  color: $dark

.container
  width: 100%
  min-height: 100vh
  background-image: url('https://softwaregecdn.azureedge.net/websitefiles/images/PartnersBackground.png')
  background-repeat: no-repeat
  background-size: cover
  background-position: right
  display: flex
  flex-direction: column
  align-items: center
  @media screen and (min-width: $Small)
    background-position: center

.why_join_container
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 135px
  h3
    @include title_md

.why_join_grid
  margin-top: 50px
  display: grid
  row-gap: 30px
  column-gap: 24px
  grid-template-columns: 1fr
  grid-auto-rows: auto
  margin-bottom: 100px
  @media screen and (min-width: $Medium)
    grid-template-columns: 1fr 1fr
    grid-auto-rows: auto
    margin-bottom: 100px
  @media screen and (min-width: $Large)
    grid-template-columns: 1fr 1fr 1fr
    grid-auto-rows: 1fr
    margin-bottom: 220px

.inner_container
  flex: 1
  display: flex
  gap: 40px
  align-items: center
  justify-content: space-between
  flex-direction: column
  @media screen and (min-width: $Medium)
    flex-direction: row

.header_text
  flex-basis: 690px
  margin-top: -50px
  @media screen and (max-width: $Extra-large)
    flex-basis: 570px
  @media screen and (max-width: $Large)
    flex-basis: 435px
  @media screen and (max-width: $Medium)
    flex: 1
  @media screen and (max-width: $Small)
    margin-top: 75px
    flex: 1

.register_form
  flex: 1
  max-width: 480px
  background-color: $white
  align-self: center
  padding: 5px
  padding-top: 40px
  padding-bottom: 40px
  box-shadow: 0px 0px 30px #1818181A
  border-radius: 10px
  margin-bottom: 30px
  @media screen and (min-width: $Small)
    padding: 40px
    width: 480px
  @media screen and (min-width: $Medium)
    padding: 40px
    width: auto
.container2
  width: 100%
  height: 100vh
  background-color: gray

.title
  font-family: 'MontserratRegular'
  font-size: 55px
  line-height: 67px
  text-align: left
  @media screen and (max-width: $Large)
    font-size: 34px
    line-height: 42px
  @media screen and (max-width: $X-Small)
    // margin: 0 0 21px 0px
    font-size: 32px
    line-height: 39px
  @media screen and (min-width: $Small)
    text-align: center
  @media screen and (min-width: $Medium)
    text-align: left

  span
    color: $yellow

.desc_text
  font-family: 'MontserratRegular'
  text-align: left
  margin-top: 34px
  font-size: 16px
  line-height: 24px
  @media screen and (min-width: $Small)
    text-align: center
  @media screen and (min-width: $Medium)
    text-align: left

.card
  box-shadow: 0px 0px 30px #1818181A
  border-radius: 10px
  display: flex
  align-items: center
  justify-content: center
  background-color: $skyblue
  font-size: 16px
  font-family: 'MontserratBold'
  color: $white
  @media screen and (min-width: $Extra-large)
    font-size: 21px

  @media screen and (max-width: $Small)
    align-self: flex-start
    justify-self: center
    padding: 10px 45px 10px 45px
  &:hover
    background-color: $yellow
    transition: color 300ms, background-color 300ms

@import "../../../../Assets/sass/_variables"
@import "../../../../Assets/sass/mixins"

.contactInfo
    display: grid
    grid-template-columns: 1fr
    gap: 30px
    background-color: $grayblue
    border-radius: 10px
    padding: 20px
    @media screen and (min-width: $Small)
        grid-template-columns: 1fr 1fr
    @media screen and (min-width: $Large)
        grid-template-columns: 1fr

.contactInfoContainer
    flex: 1
    display: flex
    gap: 25px
    flex-direction: column
    @media screen and (min-width: $Large)
        gap: 30px
    @media screen and (min-width: $Extra-large)
        gap: 35px

.editButton
    @media screen and (min-width: $Large)
        align-self: flex-end

.socialLinksContainer

    display: flex
    gap: 10px
    padding-top: 20px
    border-top: solid
    border-color: $arrowColorLight
    border-top-width: 2px
    grid-column: 1 / -1

.socialLink
    display: flex
    align-items: center
    justify-content: center
    width: 30px
    height: 30px
    background-color: #999B9C
    border-radius: 10px
    transition: all 300ms
    &:hover
        @media screen and (min-width: $Medium)
            background-color: $skyblue

.socialInfoWrapper
    display: flex
    align-items: flex-start
    gap: 20px

.socialInfoIcon
    display: flex
    align-items: center
    justify-content: center
    width: 40px
    height: 40px
    background-color: $white
    border-radius: 4px

.directionsLink
    display: flex
    align-items: center
    gap: 10px
    color: $skyblue
    font-family: 'MontserratRegular'
    font-size: $xxs
    transition: all 300ms
    &:hover
        @media screen and (min-width: $Medium)
            color: $black

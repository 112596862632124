@import "../../../Assets/sass/_variables"

.CompanyTooltip
    position: relative
    z-index: 15

    div
        svg
            z-index: 14
            height: 14px
            width: 14px
            display: flex

    path
        fill: $skyblue!important

    .notVerified
        svg
            path
                fill: $yellow!important

    .tooltip
        position: absolute
        box-shadow: 0 0 30px $boldwhite
        padding: 20px
        z-index: 10
        background: $white
        border-radius: 8px
        transform: translate(-50%, 0)
        display: none

        .tooltipWrapper
            display: grid
            gap: 12px

            h5
                font-family: "MontserratBold"
                font-size: $xs
                white-space: nowrap

            span
                display: flex
                align-items: center
                white-space: nowrap

                svg
                    margin: 0 12px 0 0
                    height: 14px
                    width: 14px
                    margin-left: 0!important
                    path
                        fill: $skyblue!important

                p
                    font-size: $xxs
                    font-family: 'MontserratRegular'
                    display: flex
                    align-items: center

                    p
                        margin-left: 5px

                a
                    margin-left: 5px
                    font-size: $xxs
                    font-family: 'MontserratRegular'

            .notVerified
                svg
                    path
                        fill: $yellow!important

    &:hover
        @media screen and (min-width: $Small)
            .tooltip
                display: flex

@import "../../../../Assets/sass/_variables"
@import "../../../../Assets/sass/mixins"

.infoContainer
  flex: 1
  display: flex
  flex-direction: column
  gap: 5px
  word-break: break-all

.title
  font-family: 'MontserratBold'
  font-size: $xxs
  // margin-bottom: 20px
  display: flex
  align-items: center
  justify-content: space-between

  span
    color: $errorRed

  @media screen and (min-width: $Small)
    font-size: $xs

  button
    background: $grayblue
    border-radius: 30px
    padding: 7.5px 20px
    color: $skyblue
    font-family: 'MontserratMedium'
    font-size: $xs
    margin-left: 5px
    white-space: nowrap

.subText
  font-family: 'MontserratRegular'
  font-size: $xxs
  color: inherit
  @media screen and (min-width: $Small)
    font-size: $xs
$white: #fff
$boldwhite: #F5F5F5
$snowWhite: #e6e6e6
$lightWhite: #f7f7f7
$yellow: #f7c63e
$lightYellow: #F7C63E1A
$yellowOpacity: #f7c63e1f
$dark: #181818
$dark5f: #1818185f
$dark1f: #1818181f
$darkGrey: #1818184D
$darkOpacity: #1818187f
$grey: #1818181A
$lightgrey: #A3A3A3
$lightlightgrey: #A3A3A34D
$skyblue: #3E66F8
$skyblueOpacity: #3E66F83f
$skyblueLight: #3E66F81f
$errorRed: #EE5253
$errorRedOpacity: #EE52531a
$shadow: #1818181a
$codGray: #1818180D
$lightGreen: #2ECC71
$black: #000000
$arrowColor: #747474
$arrowColorLight: #74747419
$purple: #EBEFFF
$grayblue: #F5F7F8
$darkGray: #EFF3F5
$versusGray: #E3E3E3
$tagBorder: #74747433
$green: #2DC76E
$errorRedLight: #FEEDED
$gray9f: #9FACBB

$font10: 10px
$xxxs: 12px
$newXS: 13px
$xxs: 14px
$xs: 16px
$sm: 18px
$smd: 21px
$md: 24px
$fontSize28: 28px
$lg: 34px
$xl: 55px

// new
$XX-Small: 320px
$X-Small: 425px
$Small: 768px
$Medium: 1024px
$Large: 1366px
$Extra-large: 1600px
$Extra-extra-large: 1920px

*
  margin: 0
  padding: 0
  box-sizing: border-box

html, body 
  height: 100%

ul, ol
  list-style-type: none

button
  border: none
  cursor: pointer
  background: none

a
  text-decoration: none

input
  border: none
  outline: none

textarea
  outline: none
  border: none

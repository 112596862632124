@import "../../../Assets/sass/_variables"
@import "../../../Assets/sass/mixins"

.aboutCompanyTextContainer
    font-size: $xxs
    margin-top: 30px
    margin-bottom: 30px
    padding-bottom: 30px
    font-family: 'MontserratRegular'
    border-bottom: solid
    border-color: $boldwhite
    border-bottom-width: 2px
    line-height: 26px
    @media screen and (min-width: $Small)
        font-size: $xs
        margin-top: 60px
        margin-bottom: 60px
        padding-bottom: 60px
    @media screen and (min-width: $Large)
        margin-top: 30px

    p
        color: $skyblue
        text-decoration: underline
        cursor: pointer

.aboutCompanyContainer
    display: flex
    flex-direction: column
    gap: 30px
    margin-top: 40px
    @media screen and (min-width: $Small)
        margin-top: 60px
    @media screen and (min-width: $Large)
        flex-direction: row

.leftContainer
    flex: 2

    @media screen and (min-width: $Large)
        padding-right: 100px
        border-right: solid
        border-color: $boldwhite
        border-right-width: 2px
    @media screen and (min-width: $Extra-large)
        padding-right: 120px

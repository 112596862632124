.fullPageLoader
    background-color: #f0f0f0
    height: 100vh
    width: 100%
    animation: skeleton 1s infinite alternate

@keyframes skeleton
    0%
        opacity: 0.6
    100%
        opacity: 1
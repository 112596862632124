@import "../sass/_variables"
.base_container
  margin: 0 auto
  max-width: 1370px
  @media screen and (min-width: $XX-Small)
    max-width: calc(100% - 40px)
  @media screen and (max-width: $XX-Small)
    max-width: calc(100% - 40px)
  @media screen and (min-width: $Small)
    max-width: 642px
  @media screen and (min-width: $Medium)
    max-width: 894px
  @media screen and (min-width: $Large)
    max-width: 1242px
  @media screen and (min-width: $Extra-large)
    max-width: 1370px
  @media screen and (min-width: $Extra-extra-large)
    max-width: 1370px

// html, body 
//   height: 100%

.new_base_container
  transition: all 0.3s ease-in-out
  @media screen and (min-width: $XX-Small)
    padding: 0 20px

  @media screen and (min-width: $Small)
    padding: 0 63px

  @media screen and (min-width: $Medium)
    padding: 0 65px

  @media screen and (min-width: $Large)
    padding: 0 62px

  @media screen and (min-width: $Extra-large)
    padding: 0 95px

  @media screen and (min-width: $Extra-extra-large)
    padding: 0 255px

body
  #root
    height: 100%
    width: 100%

    .app
      height: 100%
      width: 100%
      display: flex
      flex-flow: column nowrap
      overflow-x: hidden

body.fixed
  width: 100%
  height: 100%
  overflow: hidden

.redAsterisk
  color: $errorRed

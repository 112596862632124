@import "../../../../Assets/sass/variables"

.button
  font-family: MontserratRegular
  background-color: $grayblue
  color: $skyblue
  font-size: $xs
  border: none
  border-radius: 30px
  padding: 7px 20px 7px 20px
  transition: all 300ms
  @media screen and (min-width: $Extra-large)
    padding: 10px 20px 10px 20px
  &:hover
    background-color: $skyblue
    color: $white

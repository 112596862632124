@import "../../../Assets/sass/_variables"

footer
  background: $grayblue
  padding: 54px 0 0
  border-top: 1px solid $versusGray

  .content
    .mobileContactUs
      display: grid
      gap: 20px
      @media screen and (min-width: $Small)
        display: none
    h4
      color: $dark
      font-family: 'MontserratRegular'
      line-height: 19px
      font-size: $xs
      white-space: nowrap
      @media screen and (min-width: $Small)
        font-size: $sm

    a
      line-height: 19px
      font-family: 'MontserratRegular'
      color: $dark
      font-size: $xs
      display: flex
      align-items: center
      transition: all 0.3s linear

      &:hover
        color: $skyblue

      &:has(p)
        font-weight: 600
        white-space: nowrap

      p
        margin-left: 5px
        font-weight: normal

    .socialIcons
      margin-top: 10px
      display: flex
      align-items: center

      a
        margin-right: 10px
        background: $skyblue
        display: flex
        justify-content: center
        align-items: center
        height: 30px
        width: 30px
        border-radius: 10px
        transition: all 0.3s linear
        &:hover
          background: $yellow

    .contentWrapper
      display: grid
      gap: 30px
      grid-template-columns: repeat(2, 1fr)
      grid-template-areas: "products aboutUs" "resources ." "contactUs ."

      @media screen and (min-width: $Medium)
        display: flex
        justify-content: space-between

      .aboutUs
        grid-area: aboutUs

        a
          white-space: nowrap
      @media screen and (min-width: $Small)
        grid-area: auto

      .products
        grid-area: products
        @media screen and (min-width: $Small)
          grid-area: auto

      .resources
        grid-area: resources
        @media screen and (min-width: $Small)
          grid-area: auto

      .contactUs
        grid-area: contactUs
        display: none
        @media screen and (min-width: $Small)
          display: grid
          grid-area: auto
        @media screen and (min-width: $Medium)
          >div
            height: min-content

      >div>div
        display: grid
        gap: 20px

  .copyRight
    background: $darkGray
    margin-top: 55px
    padding: 20px 0

    .copyRightWrapper
      .mobile
        .copyRightText
          text-align: center
          margin-top: 17px
          font-size: $xxs
          font-family: 'MontserratRegular'

          @media screen and (min-width: $Small)
            display: none
            font-size: $xs

        .copyrightContent
          display: flex
          align-items: center
          justify-content: space-between
          @media screen and (min-width: $Medium)
            justify-content: unset

          .langWrapper
            @media screen and (min-width: $Medium)
              width: 100%
              display: flex
              align-items: center
              justify-content: flex-end

            .langContainer
              display: flex
              align-items: center
              @media screen and (min-width: $Medium)
                min-width: 230px

              >p
                font-size: $newXS
                color: $dark
                font-family: 'MontserratRegular'
                margin-left: 10px

              &.georgian
                @media screen and (min-width: $Medium)
                  min-width: 248px

          .copyRightText
            display: none
            margin-top: 0
            white-space: nowrap
            font-family: 'MontserratRegular'
            @media screen and (min-width: $Small)
              display: flex
            @media screen and (min-width: $Medium)
              margin-left: 60px

          .logo
            display: flex
            height: 24px
            width: 97px
            flex-shrink: 0
            position: relative
            @media screen and (min-width: $Small)
              &::after
                content: ''
                position: absolute
                background: $dark1f
                right: -30px
                height: 20px
                width: 1px
                top: 50%
                transform: translate(0, -50%)

            img
              height: 100%
              width: 100%

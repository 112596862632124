@import "../../../../Assets/sass/_variables"
@import "../../../../Assets/sass/mixins"

.overviewContainer
    display: grid
    grid-template-columns: 1fr
    gap: 30px
    margin-top: 30px
    padding-bottom: 35px
    border-bottom: solid
    border-color: $boldwhite
    border-bottom-width: 2px
    @media screen and (min-width: $Small)
        margin-top: 60px
        padding-bottom: 60px
        grid-template-columns: 1fr 1fr

    @media screen and (min-width: $Large)
        border: none

.modalWrapper
    padding: 30px

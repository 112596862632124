@import "../../../../Assets/sass/_variables"
.modal
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100%
    background-color: #1818186e
    z-index: 53
    .spareArea
        height: 100%
        width: calc(100% - 455px)
        svg
            width: 30px
            height: 30px
    .container
        height: calc(100% - 20px)
        margin: 10px
        width: 455px
        position: absolute
        top: 0px
        z-index: 51
        border-radius: 10px
        background: #FFF
        right: 10px
        overflow-y: auto
        border: none
        @media screen and (max-width: 767px)
            width: 300px
            margin: 10px 0

    .heading
        display: flex
        justify-content: space-between
        align-items: center
        width: 90%
        margin: 0 auto
        padding-top: 28px
        font-size: $sm
        cursor: default
        @media screen and (max-width: $Large)
            font-size: $xs
        .actionType
            display: flex
            font-family: 'MontserratBold'
            color: #747474
            width: fit-content
            justify-content: space-between
            align-items: center
            img
                margin-right: 10px
        .close_icon
            display: flex
            align-items: center
            cursor: pointer
            svg
                font-size: 30px
    .children
        width: 100%
        height: calc(100% - 120px)
    .divider
        width: 90%
        height: 1px
        background: rgba(128,128,128,0.2)
        margin: 30px auto 15px auto

.hidden
    display: none

.subTitle
    font-family: 'MontserratRegular'
    font-size: $xxxs
    margin-top: 5px

.titleContainer
    display: flex
    flex-direction: column
    align-items: flex-start

.red
    color: $errorRed

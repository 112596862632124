@import "../../../../Assets/sass/_variables"
.component
  background: #EFF3F5

.content
  display: flex
  flex-flow: row nowrap
  justify-content: space-between
  padding: 13px 0 12px
  align-items: center

.left_side

.list
  display: flex
  flex-flow: row nowrap
  li
    &:not(:first-child)
      margin-left: 14px

.list_item
  position: relative

  & + &
    &::after
      content: ''
      position: absolute
      top: calc(50% - 7px)
      left: -7px
      width: 1px
      height: 14px
      background: $dark
      transform: rotate(23deg)
      pointer-events: none

.list_link
  font-family: 'MontserratRegular'
  font-size: 13px
  color: $dark

.right_side

.back_btn
  font-family: 'MontserratRegular'
  font-size: 13px
  color: $dark
  display: flex
  align-items: center
  transition: color 300ms
  padding: 3px 0

  &:hover
    color: $skyblue
  svg
    margin-right: 8px

.back_btn_txt
  display: none
  @media screen and (min-width: $Small)
    display: block

@import '../../../../Assets/sass/_variables'

.RadioSelect
    width: 100%
    position: relative
    label
        margin-bottom: 10px
        font-size: $newXS
        font-family: 'MontserratSemiBold'
        display: block
    .input
        display: flex
        flex-direction: column
        width: 100%
        background: $grayblue
        border-radius: 10px
        overflow: hidden
        padding: 0 20px
        position: relative
        border: 2px solid transparent
        cursor: text
        height: 40px
        &:hover
            border: 2px solid $skyblue
        @media screen and (min-width: $Extra-large)
            padding: 0 30px
            height: 60px

        span
            position: absolute
            left: 20px
            color: $lightgrey
            font-family: 'MontserratBold'
            font-size: $xxs
            transition: all 0.3s
            transform: translate(0, -50%)
            top: 50%

            @media screen and (min-width: $Extra-large)
                font-size: $sm
                left: 30px

        input
            background: $grayblue
            font-family: 'MontserratBold'
            font-size: $xxs
            height: 100%
            width: 100%
            @media screen and (min-width: $Extra-large)
                font-size: $sm

    .options
        position: absolute
        border-radius: 10px
        box-shadow: 0px 0px 30px $grey
        padding: 20px
        width: 100%

        background: $white
        z-index: 100
        display: grid
        gap: 20px
        max-height: 300px
        overflow-y: auto
        overflow-x: hidden
        top: 70px

        @media screen and (min-width: $Extra-large)
            top: 91px

        .option
            display: flex
            align-items: center
            cursor: pointer

            img
                height: 40px
                width: 40px
                border-radius: 10px
                margin-right: 10px

            p
                font-size: $xs
                font-family: 'MontserratSemiBold'
                font-weight: 600

            svg
                margin-left: 10px

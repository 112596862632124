@import "../../../Assets/sass/_variables"

.cookiesmodal
    width: 100%
    position: fixed
    bottom: 0px
    background: $dark
    background: rgba(24, 24, 24, 0.95)
    z-index: 20

    .cookies_toggler
        position: absolute
        width: 24px
        height: 17px
        right: 60px
        top: 45px
        transform: translate(0, -50%)
        @media screen and (max-width: $Medium)
            right: 30px
            top: 25px
        @media screen and (max-width: $X-Small)
            right: 10px
            top: 20px

        &::before, &::after
            content: ''
            position: absolute
            width: 100%
            height: 6px
            background-color: $white
            border-radius: 5px

        &::before
            top: 0
            left: 0
            transform: rotate(45deg)
            top: calc(50% - 3px)

        &::after
            bottom: 0
            left: 0
            transform: rotate(-45deg)
            bottom: calc(50% - 3px)
    .cookies_content
        max-width: 74%
        display: flex
        flex-direction: column
        justify-content: center
        margin: 10px auto

    .cookies_copy
        font-size: $xs
        color: $white
        line-height: 26px
        font-family: 'MontserratRegular', 'fallback Times'
        margin-top: 30px

        @media screen and (max-width: $X-Small)
            font-size: $xxxs
            line-height: 21.5px

        .strong
            font-family: 'MontserratBold'
        .link
            color: $skyblue
            text-decoration: underline
            font-family: 'MontserratBold'
    .cookies_action
        display: flex
        width: 310px
        justify-content: space-between
        margin-top: 30px
        @media screen and (max-width: $X-Small)
            width: 100%

        button
            width: 140px
            height: 44px
            border-radius: 12px
            font-family: 'MontserratBold'
            border: none
            @media screen and (max-width: $X-Small)
                height: 40px
                width: 35vw
            @media screen and (max-width: $XX-Small)
                width: 110px

        .accept
            background-color: $skyblue
            color: $white
        .decline
            background-color: #FFFFFF
            color: #747474

@font-face
    font-family: 'fallback Times'
    src: local("Times New Roman")
    size-adjust: 120%

@import "../../../../Assets/sass/_variables"
@import "../../../../Assets/sass/mixins"

.infoForm
    padding: 30px
    .phoneWrapper
        display: flex
        .phoneCode
            margin-right: 10px

.label
    font-family: 'MontserratSemiBold'
    font-size: $xxs
    margin-bottom: 10px

.submitButton
    width: 100%
    background-color: $skyblue
    transition: all 300ms
    &:hover
        background-color: $yellow

.horizontalDivider
    width: 100%
    height: 1px
    background-color: #F5F5F5
    margin-bottom: 30px

.errorBox
    display: flex
    align-items: center
    justify-content: center
    background-color: #EE52531A
    color: $errorRed
    height: 60px
    border-radius: 10px
    font-family: 'MontserratRegular'
    margin-bottom: 30px
    font-size: $sm

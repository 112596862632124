@import "../../../Assets/sass/_variables"
@import "../../../Assets/sass/mixins"

.component_partners
  z-index: 50
  background-color: #F5F7F8 !important

.viewAs
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  position: fixed
  top: 0
  background: $yellow
  padding: 5px 0
  z-index: 1000

  p
    margin-right: 5px
    font-family: 'MontserratRegular'
    font-size: 1rem
    b
      margin-left: 5px

  button
    background: $black
    color: $white
    padding: 5px 10px
    font-weight: bold
    font-family: 'MontserratRegular'
    font-size: 1rem
    border-radius: 40px

.component
  position: relative
  z-index: 50
  background-color: $white
  width: 100%
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.4)
  @media screen and (min-width: $Small)
    position: sticky
    top: 0
    transition: all 0.15s linear

  &.open
    position: fixed
    height: 100%
    .burger_toggler

      &::before
        top: calc(50% - 3px)
        transform: rotate(45deg)

      &::after
        bottom: calc(50% - 3px)
        transform: rotate(-45deg)

  .navProfileIcon
    width: 20px
    height: 20px
    background: #E2E8F9
    border-radius: 4px
    display: flex
    align-items: center
    justify-content: center
    margin-right: 9px

.container

.content
  padding: 21px 0
  position: relative
  z-index: 20

.nav
  display: flex
  flex-flow: row nowrap
  justify-content: space-between
  align-items: center

.nav_left

.nav_left_content

.nav_logo_wr
  display: block
  width: 125px
  @media screen and (min-width: $Small)
    width: 154px
  @media screen and (min-width: $Extra-large)
    width: 170px

.nav_logo
  display: block
  width: 100%

.nav_middle
  display: none
  @media screen and (min-width: $Medium)
    display: block

.nav_middle_content

.nav_links
  display: flex
  flex-flow: row nowrap
  li
    &:not(:last-child)
      margin-right: 30px
    @media screen and (min-width: $Extra-large)
      margin-right: 60px

.nav_link_item

.nav_link
  font-family: 'MontserratRegular'
  font-size: $xs
  color: $dark
  transition: color 300ms
  @media screen and (min-width: $Extra-large)
    font-size: $sm

  &:hover
    color: $skyblue

  &.active
    color: $skyblue

.nav_right
  display: none
  @media screen and (min-width: $Small)
    display: block

.nav_right_content
  display: flex
  flex-flow: row nowrap
  align-items: center
  .nav_profile_info
    margin-right: 20px

.nav_sign_links
  display: flex
  flex-flow: row nowrap
  align-items: center
  margin-right: 10px
  .nav_signin_link
    &:first-child
      margin-right: 30px

.nav_signin_link
  color: $dark
  font-family: 'MontserratRegular'
  font-size: $xs
  transition: color 300ms
  white-space: nowrap
  @media screen and (min-width: $Extra-large)
    font-size: $sm
  &:hover
    color: $skyblue

.nav_signup_link
  background-color: #F7C63E
  color: $white
  font-family: 'MontserratMedium'
  font-size: $xs
  padding: 11px 29px 10px 29px
  border-radius: 10px
  white-space: nowrap
  @include buttonHover($color: $skyblue)
  @media screen and (min-width: $Extra-large)
    font-size: $sm
    height: 44px
    display: flex
    align-items: center

.nav_profile_info
  position: relative
  padding: 9px 0
  button
    span
      svg
        transition: all 0.15s ease-in-out

  &.open
    button
      span
        svg
          transform: rotate(180deg)

    .nav_profile_btn
      color: $skyblue

    .nav_profile_infolist
      display: block

.nav_profile_btn
  display: flex
  flex-flow: row nowrap
  align-items: center
  font-family: 'MontserratBold'
  font-size: $sm
  color: $dark
  transition: color 300ms
  img
    margin-right: 9px
  // font-style: normal
  // font-weight: bold

  &:hover
    color: $skyblue

.nav_profile_icon
  width: 20px
  height: 20px
  border-radius: 4px
  object-fit: cover

.nav_profile_username
  display: flex
  align-items: center

.nav_profile_infolist
  display: none
  background: $white
  position: absolute
  top: 49px
  right: 0
  width: 242px
  border-radius: 10px
  padding: 30px 29px
  box-sizing: border-box
  box-shadow: 0px 0px 30px fade-out($dark, 0.9)

  &::before
    content: ''
    position: absolute
    bottom: 100%
    right: 20px
    border-width: 11px 7px
    border-style: solid
    border-color: transparent transparent white transparent

.nav_profile_info_links
  display: grid
  gap: 21px 0
  button
    text-align: end

.nav_profile_info_link
  font-family: 'MontserratRegular'
  font-size: $sm
  color: $dark
  transition: color 300ms

  &:hover
    color: $skyblue

  &.active
    color: $skyblue

.nav_profile_logout_btnwr
  border-top: 1px solid fade-out($dark, 0.9)
  margin: 21px 0 0 0
  padding: 21px 0 0 0
  display: flex
  flex-flow: row nowrap
  justify-content: flex-end

.nav_profile_logout_btn
  display: flex
  align-items: center
  justify-content: flex-end
  font-family: 'MontserratBold'
  font-size: $sm
  &:hover
    color: $skyblue
    svg
      fill: $skyblue

  div
    display: flex

  svg
    margin-left: 9px

.nav_lang_switcher_wr
  display: none
  @media screen and (min-width: $Medium)
    display: block

.burger_toggler
  position: absolute
  width: 24px
  height: 17px
  right: 0
  top: 50%
  transform: translate(0, -50%)
  @media screen and (min-width: $Small)
    right: 50%
    top: 50%
    transform: translate(-50%, -50%)
  @media screen and (min-width: $Medium)
    display: none

  &::before, &::after
    content: ''
    position: absolute
    width: 100%
    height: 6px
    background-color: $dark
    border-radius: 5px

  &::before
    top: 0
    left: 0

  &::after
    bottom: 0
    left: 0

.mobile_menu
  position: relative
  height: calc(100% - 68px)
  background: $white
  display: flex
  flex-flow: row nowrap
  justify-content: center
  align-items: center
  overflow: auto
  @media screen and (min-width: $Small)
    height: calc(100% - 108px)
  @media screen and (min-width: $Medium)
    display: none

  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 1px
    background: transparent linear-gradient(90deg, #101330 0%, $skyblue 100%) 0% 0% no-repeat padding-box

.mobile_menu_content
  display: flex
  flex-flow: column nowrap

  height: 100%
  @media screen and (min-width: $Small)
    display: block
    height: auto

.mobile_menu_links
  display: grid
  flex-flow: column nowrap
  justify-content: center
  flex-shrink: 0
  height: 180px
  align-items: center
  @media screen and (min-width: $XX-Small)
    margin: 80px 0
  @media screen and (min-width: $Small)
    margin: 0
    height: auto

.mobile_menu_linkitem
  text-align: center
  &:not(:last-child)
    margin: 0 0 30px

.mobile_menu_link
  font-family: 'MontserratRegular'
  font-size: $sm
  color: $dark
  transition: color 300ms
  @media screen and (min-width: $Small)
    font-size: $smd

  &:hover
    color: $skyblue

  &.active
    color: $skyblue

.mobile_menu_langswitcher
  display: flex
  justify-content: center
  margin: 34px 0
  position: relative
  z-index: 1
  @media screen and (min-width: $Small)
    margin: 80px 0 34px 0

.mobile_menu_sign_links
  display: flex
  flex-flow: row nowrap
  justify-content: center
  align-items: center
  border-top: 1px solid fade-out($dark, 0.9)
  padding: 34px 0
  width: 280px
  @media screen and (min-width: $Small)
    display: none
  .mobile_menu_signin
    margin-right: 30px

.mobile_menu_signin
  color: $dark
  font-family: "MontserratRegular"
  font-size: $xs

.mobile_menu_signup
  background-color: #F7C63E
  color: $white
  font-family: "MontserratBold"
  font-size: $xs
  padding: 11px 29px 10px 29px
  border-radius: 10px

.navmob_profile_info
  position: relative
  padding: 42px 0 84px
  width: 280px
  border-top: 1px solid rgba(24, 24, 24, 0.1)
  @media screen and (min-width: $Small)
    display: none

.navmob_profile_btn
  display: flex
  flex-flow: row nowrap
  justify-content: center
  width: 100%
  align-items: center
  font-family: "MontserratBold"
  font-size: $sm
  color: $dark
  transition: color 300ms
  img
    margin-right: 9px

.navmob_profile_icon
  width: 20px
  height: 20px
  border-radius: 4px
  object-fit: cover

.navmob_profile_username
  display: flex
  align-items: center
  color: $dark
  svg
    transform: rotate(0)
    margin-left: 3px

  &.active
    color: $skyblue
    svg
      transform: rotate(180deg)

.navmob_profile_infolist
  background: $white
  border-radius: 10px
  padding: 30px 0 0

.navmob_profile_info_links
  display: grid
  gap: 21px 0

.navmob_profile_info_link
  font-family: "MontserratRegular"
  font-size: $sm
  color: $dark
  transition: color 300ms

  &:hover
    color: $skyblue

  &.active
    color: $skyblue

.navmob_profile_logout_btnwr
  border-top: 1px solid rgba(24, 24, 24, 0.1)
  margin: 21px auto 0 auto
  padding: 21px 0 0 0
  display: flex
  flex-flow: row nowrap
  justify-content: center
  width: 182px

.navmob_profile_logout_btn
  display: flex
  align-items: center
  justify-content: flex-end
  font-family: "MontserratBold"
  font-size: $sm

  svg
    margin-left: 9px

.disabled
  opacity: 0.7
  pointer-events: none

@media screen and (min-width: $Small)
  .isScrolled
    box-shadow: 0 0 10px rgba(0,0,0,0.4)

@import "../../Assets/sass/mixins"
@import "../../Assets/sass/_variables"

.container_business
    margin: 0px !important

.container
    display: flex
    flex-direction: column
    align-items: center
    margin: 90px auto 144px auto
    max-width: 450px
    @media screen and (max-width: 426px)
        padding: 0 20px
    .title
        @include title_md
    .subTitle
        @include subTitle_md
    .steps
        display: flex
        flex-direction: row
        margin-top: 55px
        width: 100%
        .step
            width: 100%
            text-align: center
            &:not(:last-child)
                margin-right: 12px

            p
                font-family: 'MontserratRegular'
                font-weight: normal
                font-size: $xxs
                color: $dark
                opacity: .3
                &.completed
                    color: $lightGreen
            .line
                width: 100%
                height: 10px
                margin-top: 10px
                background-color: #f2f2f2
                border-radius: 10px
            &.active
                > *
                    opacity: 1
                .line
                    background-color: $skyblue
            &.completed
                .line
                    opacity: .3
                    background-color: $lightGreen
            &.completed p
                color: $lightGreen

    .profileTypeSwitcher
        display: flex
        width: 100%
        margin-top: 30px
        flex-direction: row
        border: solid 2px $skyblue
        border-radius: 10px
        .switcherButton
            width: 50%
            margin: 5px
            font-family: 'MontserratBold'
            height: inherit
            font-weight: bold
            font-size: $sm
            background-color: inherit
            color: rgba(24, 24, 24, .3)
            padding-top: 14px
            padding-bottom: 14px
            &.active
                background-color: rgba(62,102,248, .3)
                color: $skyblue
    .button_group
        display: flex
        flex-direction: column
        width: 100%
        margin-top: 30px

    .social_button
        background-color: #fff !important
        color: #181818 !important
        display: flex
        align-items: center
        justify-content: space-between
        @include button($skyblue, $skyblue, white)
        margin-top: 0

        &:last-child
            margin-top: 10px
        .header_addVacancy_icon
            width: 50px
            height: 20px
            svg
                width: 23px
                height: 24px
        .header_addVacancy_text
            flex-grow: 2
        &:hover
            border: 2px solid #F7C63E
            background-color: #F7C63E

    .gray_color
        color: #8e8e8e

    .input_group_3
        margin-top: 30px
        display: flex
        align-items: center
        justify-content: center

    .signUpForm
        display: flex
        flex-direction: column
        width: 100%
        margin-top: 30px
        .select
            margin-bottom: 30px
            position: relative
            input
                margin-top: -20px
            button
                border: 1px solid $boldwhite
                border-radius: 10px
                background-color: #F5F7F8
                box-sizing: border-box
                padding: 19px 30px
                font-family: 'MontserratBold'
                font-weight: bold
                font-size: $sm
                color: $dark
                width: 100%
                text-align: start
                display: flex
                align-items: center
                justify-content: space-between

                svg
                    flex-shrink: 0

            .jobFunctionBtn
                overflow: hidden

                span
                    white-space: nowrap
                    overflow: hidden
                    text-overflow: ellipsis
                    max-width: 400px

            .dropdownDiv
                position: absolute
                display: none
                width: 100%
                font-family: 'MontserratBold'
                font-weight: bold
                background: white
                border-radius: 10px
                padding: 10px 20px
                box-sizing: border-box
                z-index: 1
                margin-top: 10px
                box-shadow: 0px 0px 30px $grey
                max-height: 200px
                overflow: scroll
                overflow-x: hidden
                &.show_dropdown
                    display: block
                .technologyName
                    background: #F5F7F8
                    padding: 10px 20px
                    border-radius: 10px
                    font-size: $xxs
                    color: $skyblue
                .listItem
                    padding: 14px 0
                .emptyValue
                    color: #1818185b
                ul li
                    cursor: pointer
                    &:hover
                        color: $skyblue
                &.show
                    display: block

            .jobFunctionsDiv
                padding: 0
                .jobFunctions
                    cursor: default
                    &:hover
                        color: unset
                    h6
                        background: $grayblue
                        padding: 10px 20px
                        font-size: $xxs
                        font-family: 'MontserratBold'

                    .fields
                        padding: 10px 20px
                        display: flex
                        flex-flow: column nowrap

                        span
                            display: flex
                            align-items: center
                            margin-bottom: 10px
                            cursor: pointer

                            p
                                margin-left: 10px

        .chooseTechnology
            margin-bottom: 30px
            color: $dark
            font-family: 'MontserratSemiBold'
            font-weight: 600
            font-size: 13px

        .select
            input
                border: 1px solid $boldwhite
                border-radius: 10px
                background-color: #F5F7F8
                box-sizing: border-box
                padding: 19px 30px
                font-family: 'MontserratBold'
                font-size: $sm
                color: $dark
                width: 100%
                &:focus
                    outline: none
                    background-color: white
                    border-color: $skyblue
                &::placeholder
                    font-size: $sm
                    color: #a3a3a3
                    font-family: 'MontserratBold'
                    font-weight: bold
                &[type='checkbox']
                    display: none
                    &:not(:checked) + .check
                        background: #EFF3F5
                        width: 30px
                        height: 30px
                        border-radius: 4px
                    &:not(:checked) + .check::before
                        content: ""
                    &:checked + .check
                        display: flex
                        justify-content: center
                        align-items: center
                        color: white
                        background: $skyblue
                        width: 30px
                        height: 30px
                        border-radius: 4px
                    &:checked + .check::before
                        display: inline-block
                        font-size: inherit
                        height: 1em
                        overflow: visible
                        vertical-align: -.125em
                        content: url("https://softwaregecdn.azureedge.net/websitefiles/icons/Icon-awesome-check.svg")

    label
        display: flex
        align-items: center
        gap: 20px
        font-size: $xxs
        cursor: pointer !important

    .stepButtons
        display: flex
        gap: 30px
        width: 100%

    .prevStep
        @include buttonHover($color: $lightgrey)
        @include button($backgroundColor: white, $borderColor: $skyblue, $textColor: $skyblue)

    .checkBox
        padding: 10px 0px

    .tags
        width: 100%
        margin-bottom: -20px
        .tag
            @include tag
        .tagCategory
            @include tagCategory

    .nextStep
        @include buttonHover($color: $yellow)
        @include button($backgroundColor: $skyblue, $borderColor: $skyblue, $textColor: white)

    .signInText
        margin-top: 30px
        font-family: 'MontserratRegular'
        font-size: $xs
        font-weight: normal
        .signInLink
            color: $skyblue
            text-decoration: none
            text-decoration: underline

    .imageUploader
        margin-top: 55px
        width: 210px
        height: 210px
        border: 2px dashed rgba(163, 163, 163, .3)
        border-radius: 200px
        display: flex
        align-items: center
        justify-content: center
        margin-bottom: 30px
        position: relative
        .imageUploadLabel
            background-color: #EFF3F5
            width: 100px
            height: 100px
            border-radius: 100px
            display: flex
            justify-content: center
            box-sizing: border-box
            input
                display: none
        .previewImg
            width: 200px
            height: 200px
            object-fit: cover
            border-radius: 100px
        .removeImg
            cursor: pointer
            top: 15px
            right: 15px
            position: absolute
            background: white
            height: 30px
            width: 30px
            border-radius: 100px
    .pictureText
        font-family: 'MontserratRegular'
        font-weight: normal
        font-size: $xs
        margin-bottom: 20px

    .welcomeImage
        margin-top: 55px
        height: 90px
        width: 90px
        border-radius: 100px
        object-fit: cover
    .welcomeName
        font-family: 'MontserratBold'
        font-weight: bold
        font-size: $smd
        margin-top: 20px
        margin-bottom: 13px
        color: $dark
    .welcomeField
        font-family: 'MontserratRegular'
        font-weight: normal
        font-size: $xs
        color: $dark
        margin-top: 10px
        margin-bottom: 30px
    hr
        height: 2px
        width: 100%
        background: $boldwhite
        border: none
        margin-bottom: 20px
